import React, { createRef } from "react" 
import Layout from "../components/layout";
import SEO from "../components/seo"; 
import ContentBlock from "../components/Content/Content"
import FilterBlock from "../components/StaticOne/FilterBlock/FilterBlock" 
import NewProjects from "../components/NewProjects/NewProjects"  

function ProjectTemplate(props) { 

  const find_more_ref = createRef();
 
  var GQLPage = props?.GQLPage;
  var GQLModules = props?.GQLModules;

  let processedImages = JSON.stringify({});
  if (GQLPage?.imagetransforms && GQLPage?.imagetransforms.Header_Banner_Image_Transforms) {
    processedImages = GQLPage?.imagetransforms.Header_Banner_Image_Transforms;
  }  
  var main_cnt_cls = (GQLPage?.Layout !== "Default" && GQLPage?.Layout !== "Buy_Landing_Page") ? "page-content" : '';
  main_cnt_cls += (GQLPage?.Layout === "Content_Full_Width" || GQLPage?.Layout === "Rightside_Block") ? " static-page" : '';

  return (
    <>
      {
        GQLPage && (
          <Layout Layout={`Without_Banner`} classNames={GQLPage.Layout} popular_search={GQLPage.popular_search} Alias={GQLPage.Alias} Select_Popular_Search={GQLPage.Select_Popular_Search} Pagename={GQLPage?.Pagename}>
            <SEO title={GQLPage.Meta_Title} description={GQLPage.Meta_Description} /> 
            {/* ====== Main Container Modules ===== */}
            <div className={`${main_cnt_cls} ${GQLPage.Page_CSS_Class ? GQLPage.Page_CSS_Class : ''}`} ref={find_more_ref}>
              {GQLModules.map((Modules, i) => {
                return (
                  <>
                    {Modules.__typename === "GLSTRAPI_ComponentModulesShowInsights" && (GQLPage.Layout !== "Default" && GQLPage.Layout !== "Buy_Landing_Page") && Modules.Show_Insights && <FilterBlock Filter_by_Tag={Modules.Filter_by_Tag ? Modules.Filter_by_Tag : ''} />}

                    {/* ======= Inner Page Content Block ====== */}
                    {GQLPage.Alias !== "careers" && GQLPage.Alias !== "reviews" && Modules.__typename === "GLSTRAPI_ComponentModulesContentBlock" &&
                      <ContentBlock Content={Modules.Content} Content_Heading_Text={Modules.Title} classNames={GQLPage.Layout} />
                    }

                    {/* ======= New project Section ====== */}
                    {Modules.__typename === "GLSTRAPI_ComponentModulesCollections" && Modules.Select_Collection === "New_Projects" && <NewProjects Title={GQLPage.Pagename} Alias={GQLPage.Alias} pstatus={GQLPage.Alias === "new-projects" ? "Sale" : "Rent"} Description={GQLPage.Content} location={props.location} region={"Qatar"} sort={"createdAt:desc"} Meta_Description={GQLPage.Meta_Description} Meta_Title={GQLPage.Meta_Title}  isNewProjectTemplate={true}/>}
                    {/* ======= New Projects Dubai Page ====== */}
                    {Modules.__typename === "GLSTRAPI_ComponentModulesCollections" && Modules.Select_Collection === "New_Projects_Dubai" && <NewProjects Title={GQLPage.Pagename} Alias={GQLPage.Alias} pstatus={GQLPage.Alias === "our-new-projects-in-dubai" ? "Sale" : "Rent"} Description={GQLPage.Content} location={props.location} region={"Dubai"} sort={"createdAt:desc"} Meta_Description={GQLPage.Meta_Description} Meta_Title={GQLPage.Meta_Title} isNewProjectTemplate={true}/>}

                  </>
                )
              })}
            </div>
          </Layout>

        )
      }
    </>
  )
}

export default ProjectTemplate
